<template>
  <article :class="{ mobile: isPhone, ie11: isIE11 }">
    <Hero heading="Our Investment Plan">
      <template slot="image">
        <picture>
          <source
            srcset="@/assets/images/Our-plan/hero-our-plan.jpg, @/assets/images/Our-plan/hero-our-plan@2x.jpg 2x"
            media="(min-width: 750px)"
            alt="A group of Electrify America fast chargers glowing green at night."
          />
          <source
            alt="A group of Electrify America fast chargers glowing green at night."
            srcset="
              @/assets/images/Our-plan/hero-our-plan-mobile.jpg,
              @/assets/images/Our-plan/hero-our-plan-mobile@2x.jpg 2x
            "
          />
          <img
            class="hero-img"
            src="@/assets/images/Our-plan/hero-our-plan-mobile.jpg"
            alt="A group of Electrify America fast chargers glowing green at night."
          />
        </picture>
      </template>
    </Hero>
    <section class="investing">
      <div class="container">
        <div class="investing-content">
          <h2>Investing in an Electric Future</h2>
          <p class="heading-context">
            Over a 10-year period (made up of four 30-month “cycles”), Electrify America plans to invest $2{{
              '\xa0'
            }}billion in Zero Emission Vehicle (ZEV) infrastructure, access, and education programs in the United{{
              '\xa0'
            }}States.
          </p>
          <h3>Highlights</h3>
          <decorative-list>
            <li>
              Of this $2{{ '\xa0' }}billion, we’re investing $800{{ '\xa0' }}million in California, one of the largest
              ZEV markets in the world.
            </li>
            <li>
              We plan to invest $1.2{{ '\xa0' }}billion in the remainder of the United{{ '\xa0' }}States, providing
              support to current electric vehicle owners and those interested in learning more about the benefits of
              driving electric vehicles.
            </li>
          </decorative-list>
          <p class="special">
            Electrify America’s National and California ZEV Investment Plans serve as a roadmap for what we do to drive
            ZEV adoption.
          </p>
        </div>
      </div>
    </section>

    <Card headlineLevel="h2" heading="Cycle 1" :maxWidth="1200">
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="desktop-width"
            src="@/assets/images/Our-plan/cycle-1.jpg"
            alt="Closeup of a person fueling an electric vehicle."
          />
        </picture>
      </template>
      <h2 class="sub-heading">(January 2017–June 2019)</h2>
      <h3 class="headline3">Plans Implemented</h3>
      <p>Electrify America implemented the following plans between January 2017 and June 2019:</p>
      <ul>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/California ZEV Investment Plan Cycle 1.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 1 California ZEV Investment Plan (PDF)</a
          >
        </li>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/Cycle 1 CA ZEV Invest Plan Supplement.pdf')"
            rel="noopener"
            target="_blank"
            >Supplement to the Cycle 1 California ZEV Investment Plan (PDF)</a
          >
        </li>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/National ZEV Investment Plan.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 1 National ZEV Investment Plan (PDF)</a
          >
        </li>
      </ul>
    </Card>

    <Card headlineLevel="h2" heading="Cycle 2" :isReverse="true" :maxWidth="1200" bgColor>
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="desktop-width"
            src="@/assets/images/Our-plan/cycle-2.jpg"
            alt="Closeup of a person fueling an electric vehicle."
          />
        </picture>
      </template>
      <h2 class="sub-heading">(July 2019–December 2021)</h2>
      <h3 class="headline3">Plans Implemented</h3>
      <p>Electrify America implemented the following plans between July 2019 and December 2021:</p>
      <ul>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/Cycle 2 California ZEV Investment Plan.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 2 California ZEV Investment Plan (PDF)</a
          >
        </li>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/Cycle 2 California ZIP Errata_0.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 2 California ZEV Investment Plan Errata (PDF)</a
          >
        </li>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/Cycle 2 National ZEV Investment Plan - Public Version vF.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 2 National ZEV Investment Plan (PDF)</a
          >
        </li>
      </ul>
    </Card>

    <Card headlineLevel="h2" heading="Cycle 3" :maxWidth="1200">
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="desktop-width"
            src="@/assets/images/Our-plan/cycle-3.jpg"
            alt="Closeup of a person fueling an electric vehicle."
          />
        </picture>
      </template>
      <h2 class="sub-heading">(January 2022–June 2024)</h2>
      <h3 class="headline3">Plans Implemented</h3>
      <p>Electrify America implemented the following plans between January 2022 and June 2024:</p>
      <ul>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/cycle3_invesment_plan.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 3 California ZEV Investment Plan (PDF)</a
          >
        </li>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/cycle3_investment_plan_epa.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 3 National ZEV Investment Plan (PDF)</a
          >
        </li>
      </ul>
    </Card>

    <Card headlineLevel="h2" heading="Cycle 4" :isReverse="true" :maxWidth="1200" bgColor>
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="desktop-width"
            src="@/assets/images/Our-plan/cycle-4.jpg"
            alt="Closeup of a person fueling an electric vehicle."
          />
        </picture>
      </template>
      <h2 class="sub-heading">(July 2024–December 2026)</h2>
      <h3 class="headline3">Looking to the Future</h3>
      <p>Electrify America is implementing the following plans between July 2024 and December 2026:</p>
      <ul>
        <li>
          <a class="link" :href="require('../../../public/pdf/cycle4-plan-national.pdf')" rel="noopener" target="_blank"
            >Cycle 4 National ZEV Investment Plan (PDF)</a
          >
        </li>
        <li>
          <a
            class="link"
            :href="require('../../../public/pdf/cycle4-plan-california.pdf')"
            rel="noopener"
            target="_blank"
            >Cycle 4 California ZEV Investment Plan (PDF)</a
          >
        </li>
      </ul>
    </Card>

    <HeroWithContentOverlay :overlayBG="false" fullWidthMobile>
      <template #image>
        <img
          loading="lazy"
          class="tinted"
          v-if="!isPhone"
          src="@/assets/images/Our-plan/reports-backdrop.jpg"
          alt="A group of Electrify America fast chargers in a tree-lined shopping center."
        />
        <img
          loading="lazy"
          class="tinted"
          v-if="isPhone"
          src="@/assets/images/Our-plan/reports-backdrop-mobile.jpg"
          alt="A group of Electrify America fast chargers in a tree-lined shopping center."
        />
      </template>
      <a rel="noopener" target="_blank" href="https://media.electrifyamerica.com/models/reports">
        <h2>Read Our Company Reports</h2>
        <p>
          See Electrify America’s latest quarterly <br v-if="!isPhone" />
          and annual reports.
        </p>
      </a>
    </HeroWithContentOverlay>

    <Card headlineLevel="h2" heading="Developing Our Plan" :isReverse="isPhone" :maxWidth="1200" bgColor>
      <template #image>
        <img
          loading="lazy"
          style="max-width: 520px"
          v-if="!isPhone"
          src="@/assets/images/Our-plan/developing-our-plan.jpg"
          alt="Three Electrify America DC fast chargers in a parking lot."
        />
        <img
          loading="lazy"
          v-if="isPhone"
          style="margin-top: 30px"
          src="@/assets/images/Our-plan/developing-our-plan-mobile.jpg"
          alt="Three Electrify America DC fast chargers in a parking lot."
        />
      </template>
      <p>To develop each ZEV Investment Plan, Electrify America invited feedback from:</p>
      <decorative-list>
        <li>
          <p>
            Federal, state, and local governments, Native American tribes, and the Department of Energy (DOE) National
            Laboratories
          </p>
        </li>
        <li>
          <p>Universities, think tanks, and technology companies</p>
        </li>
        <li>
          <p>Electric vehicle charging companies and electric vehicle driver associations</p>
        </li>
      </decorative-list>
      <h5>Thank you for helping shape our future.</h5>
    </Card>
  </article>
</template>

<script>
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';
import Card from '@/components/Card/Card.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'our-plan',
  metaInfo: {
    title: 'Our Zero Emission Vehicle Investment Plan| Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Between 2017 and 2027, Electrify America will have invested $2 billion in Zero Emission Vehicle (ZEV) infrastructure, access, and education in the U.S.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/our-plan/' }],
  },
  components: {
    Card,
    DecorativeList,
    HeroWithContentOverlay,
    Hero,
  },
  computed: {
    isIE11() {
      return navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1;
    },
    isPhone() {
      return this.$resize && this.$mq.below(850);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

img {
  width: 100%;
  border-radius: 8px;
}
h1,
h2,
h3 {
  margin: 0 0 24px 0 !important;
}

h2,
.sub-heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.13;
  letter-spacing: 0.64px;
}

h3 {
  font-size: 24px;
  font-weight: $fw-regular;
  line-height: 1.5;
  letter-spacing: 0.48px;
}

p {
  margin: 0 0 24px 0;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

li {
  margin-bottom: 8px;
}

.hero-with-overlay {
  margin: 0px auto 70px;
  @media (min-width: 850px) {
    margin: 112px auto;
  }

  h2 {
    font-size: 32px;
    @media (min-width: 850px) {
      font-size: 48px;
    }
  }

  ::v-deep &__content {
    max-width: 100%;
    @media (max-width: 500px) {
      top: 50%;
    }
  }
}

// Utility Classes

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 24px;
}

// Section Styling

.investing {
  background-color: $c-secondary-background;
  padding-top: 100px;
  @media (max-width: 750px) {
    padding-top: 40px;
  }

  .hero-img {
    margin-top: -175px;
    margin-bottom: 102px;
  }

  .investing-content {
    margin-left: 121px;
  }

  .heading-context {
    margin-bottom: 56px;
  }

  .special {
    margin-top: 70px;
    padding-bottom: 50px;
  }
}

.mobile {
  padding-top: 0 !important;

  h1 {
    font-size: 30px;
    letter-spacing: 1.5px;
    line-height: 1.33;
    margin: 32px 0 18px !important;
  }

  .investing {
    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 21px;
    }

    .hero-img {
      margin-top: -200px;
      margin-bottom: 40px;
    }

    .investing-content {
      margin-left: 0;
    }
  }
}

.desktop-width {
  @media (min-width: 800px) {
    max-width: 500px !important;
  }
}
.headline3 {
  font-size: 24px;
  font-weight: $fw-regular;
  line-height: 1.5;
  letter-spacing: 0.48px;
}

.tinted {
  filter: brightness(50%);
}
</style>
